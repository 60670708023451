<template>
  <div>
    <v-navigation-drawer
      :app="!$vuetify.breakpoint.xsOnly"
      v-model="showNotificationPane"
      right
      :permanent="showNotificationPane"
      hide-overlay
      width="375px"
      stateless
      :absolute="$vuetify.breakpoint.xsOnly"
      :style="{
        'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
        'background-color': '#fafafa',
      }"
    >
      <NotificationsPane
        v-if="showNotificationPane"
        @close-notification-pane="showNotificationPane = false"
      />
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showGisInfoPanel"
      style="z-index: 100"
      hide-overlay
      width="375px"
      :permanent="showGisInfoPanel && $vuetify.breakpoint.smAndUp"
      :right="$vuetify.breakpoint.smAndUp"
      bottom
      :stateless="$vuetify.breakpoint.smAndUp"
      class="elevation-3"
    >
      <div
        class="d-flex flex-column background"
        style="height: 100%"
        v-if="showGisInfoPanel"
        id="infoPanel"
      >
        <GisInfoTopCard
          class="background"
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :globalId="selectedFeatureGlobalId"
          :objectId="selectedActionItem.assignerObjectId"
          :sharedSite="sharedSite"
          @gis-info-panel-close="onGisInfoPanelClose"
          @open-expand-panel-dialog="showExpansionPanelDialog = true"
          @edited-site="createSharedSiteIfNotExist"
        />

        <GisInfoButtonBar
          class="background"
          @gis-info-button-clicked="gisInfoTab = $event"
          v-if="!['S'].includes(selectedLayerType)"
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :globalId="selectedFeatureGlobalId"
          :objectId="selectedActionItem.assignerObjectId"
          :selectedGisInfoIndex="selectedGisInfoIndex"
          :taskCount="taskCount"
        />

        <div class="background">
          <TicketFormDefDropdown
            inRightPane
            v-if="showGisInfoPanel"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedGisInfoObj="selectedGisInfoObj"
            @show-edit-dialog="openGisInfoPanels = [1]"
          />
        </div>

        <v-divider></v-divider>

        <SharePublicSite
          v-if="showGisInfoPanel"
          v-show="selectedLayer && selectedLayer.site_enabled"
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :objectId="selectedActionItem.assignerObjectId"
          :attributes="rawGisInfoAttributes"
          :sharedSite="sharedSite"
          :globalId="selectedFeatureGlobalId"
          @public-link-created="createSharedSiteIfNotExist"
          @removed-sharing="createSharedSiteIfNotExist"
          @site-name-saved="createSharedSiteIfNotExist"
        />

        <v-divider
          v-if="selectedLayer && selectedLayer.site_enabled"
        ></v-divider>

        <div
          v-if="['F', 'U'].includes(selectedLayerType)"
          class="flex-grow-1 overflow-y-auto background"
        >
          <SortedGisInfo
            v-if="showGisInfoPanel"
            inRightPane
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            :objectId="selectedActionItem.assignerObjectId"
            :globalId="selectedFeatureGlobalId"
            @esri-field-saved="getGisInfo"
            @utilisync-field-saved="getGisInfo"
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showFeatureLayerGisInfoPanel"
      right
      :permanent="showFeatureLayerGisInfoPanel"
      style="z-index: 100"
      hide-overlay
      width="375px"
      stateless
    >
      <div
        class="d-flex flex-column background"
        style="height: 100%"
        v-if="showFeatureLayerGisInfoPanel"
        id="infoPanel"
      >
        <FeatureItemGisInfoTopCard
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :objectId="selectedActionItem.assignerObjectId"
          :globalId="selectedFeatureGlobalId"
          :attributes="featureItemAttributes"
          :sharedSite="sharedSite"
          @gis-info-panel-close="showFeatureLayerGisInfoPanel = false"
          @open-expand-panel-dialog="showExpansionPanelDialog = true"
          @edited-site="createSharedSiteIfNotExist"
        />

        <FeatureItemButtonBar
          @gis-info-button-clicked="gisInfoTab = $event"
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :objectId="selectedActionItem.assignerObjectId"
          :globalId="selectedFeatureGlobalId"
          :taskCount="taskCount"
        />

        <div class="background">
          <TicketFormDefDropdown
            inRightPane
            v-if="showFeatureLayerGisInfoPanel"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedGisInfoObj="selectedGisInfoObj"
            @show-edit-dialog="openGisInfoPanels = [1]"
          />
        </div>

        <v-divider></v-divider>

        <SharePublicSite
          v-if="showFeatureLayerGisInfoPanel"
          v-show="selectedLayer && selectedLayer.site_enabled"
          :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          :objectId="selectedActionItem.assignerObjectId"
          :attributes="rawGisInfoAttributes"
          :sharedSite="sharedSite"
          :globalId="selectedFeatureGlobalId"
          @public-link-created="createSharedSiteIfNotExist"
          @removed-sharing="createSharedSiteIfNotExist"
          @site-name-saved="createSharedSiteIfNotExist"
        />

        <v-divider
          v-if="selectedLayer && selectedLayer.site_enabled"
        ></v-divider>

        <div class="flex-grow-1 overflow-y-auto background">
          <SortedGisInfo
            v-if="showFeatureLayerGisInfoPanel"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            @esri-field-saved="getGisInfo"
            @utilisync-field-saved="getGisInfo"
          />
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showTasksTab"
      right
      :permanent="showTasksTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Tasks</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTasksTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showTasksTab">
          <TasksTab
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            @update-task-count="taskCount = $event"
          />
          <TasksTab
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
            @update-task-count="taskCount = $event"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showTicketLogTab"
      right
      :permanent="showTicketLogTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Log</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTicketLogTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showTicketLogTab">
          <Log
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          />
          <Log
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showDocsTab"
      right
      :permanent="showDocsTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Documents</div>
                <v-btn
                  icon
                  text
                  @click="
                    showDocsTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showDocsTab">
          <DocsTab
            class="overflow-y-auto"
            v-if="['U'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          />
          <DocsTab
            class="overflow-y-auto"
            v-else-if="['F'].includes(selectedLayerType)"
            :globalId="selectedFeatureGlobalId"
            :objectId="selectedActionItem.assignerObjectId"
            :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
          />
        </template>
      </div>
    </v-navigation-drawer>

    <div
      class="d-flex flex-wrap justify-space-between align-center"
      ref="filterDisplay"
    >
      <div class="d-flex px-5 align-center">
        <TableViewDropdown
          class="pt-4"
          v-if="onMapPage"
          @selected-table="$emit('selected-table', $event)"
          :currentSelectedTable="this.newSelectedTable"
        />
        <v-btn
          text
          color="#3F51B5"
          class="px-1"
          @click="showActionItemsFilterDialog = true"
          id="actionItemFilter"
        >
          <v-icon> {{ mdiFilter }}</v-icon>
          Filter
        </v-btn>

        <ActionItemsFilterChoicesDisplay
          :filterChoices="filterChoices"
          :users="users"
          :siteInfos="siteInfos"
          :mapServices="mapServices"
          @remove-filter="removeFilter"
          @toggle-open-status="toggleOpenStatus"
          class="px-2"
        />
      </div>

      <div class="px-5">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="px-1"
              v-bind="attrs"
              v-on="on"
              text
              color="#3F51B5"
              id="addFieldActionItems"
            >
              <v-icon dark class="mr-1">
                {{ mdiPlusCircle }}
              </v-icon>
              Field
            </v-btn>
          </template>

          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="h of nonEmptyHeaders"
                :key="h.value"
                @click.stop
                v-model="headersEnabled[h.value]"
                :label="h.text"
                class="py-0 my-0"
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>

    <v-data-table
      :headers="filteredHeaders"
      :items="mappedActionItems"
      item-key="actionItemId"
      class="pa-0 ma-0 cursor-pointer"
      @click:row="onRowClick"
      hide-default-footer
      disable-pagination
      fixed-header
      :height="tableHeight"
    >
      <template v-slot:no-data>
        <div
          v-html="
            isOnline
              ? 'No data available'
              : 'This device is offline. <u>Reload</u> the page when you are back online to load the Action Items'
          "
        ></div>
      </template>

      <template v-slot:[`item.menu`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text id="actionItemOverflow">
              <v-icon dark>
                {{ mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list class="px-0 mx-0">
            <v-list-item
              class="mx-0 gap"
              @click="
                selectedActionItem = actionItems.find(
                  (a) => a.action_item_id === item.actionItemId
                );
                selectedActionItemId = selectedActionItem.action_item_id;
                showActionItemDialog = true;
              "
              v-if="canEditOrCancel(item)"
            >
              <v-icon>{{ mdiNotePlusOutline }}</v-icon>
              {{ getActionName(item) }} Action Item
            </v-list-item>
            <v-list-item class="mx-0 gap" @click="openInfoPanel(item)">
              <v-icon>{{ mdiBookOpenOutline }}</v-icon>
              Open Info Panel
            </v-list-item>
            <v-list-item class="mx-0 gap" @click="editActionItem(item)">
              <v-icon>{{ mdiPencil }}</v-icon> Edit Action Item
            </v-list-item>
            <v-list-item
              class="mx-0 gap"
              @click="openConfirmActionItemCancelDialog(item)"
              v-if="canEditOrCancel(item)"
            >
              <v-icon>{{ mdiDelete }}</v-icon> Cancel Action Item
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item.status === 'rejected'" style="color: red">
          {{ item.status | actionItemStatus }}
        </span>
        <span v-else>
          {{ item.status | actionItemStatus }}
        </span>
      </template>

      <template v-slot:[`item.dueBy`]="{ item }">
        {{ item.dueBy | formatDayDate }}
      </template>

      <template v-slot:[`item.createdOn`]="{ item }">
        {{ item.createdOn | formatDayDate }}
      </template>

      <template v-slot:[`item.assignedToUserName`]="{ item }">
        {{ getUserName(item) }}
      </template>

      <template v-slot:[`item.assignerObjectId`]="{ item }">
        {{ getSiteName(item) }}
      </template>

      <template v-slot:[`item.assignedOn`]="{ item }">
        {{ item.assignedOn | formatFullDate }}
      </template>
    </v-data-table>

    <div class="d-flex justify-center py-2" ref="bottomBar">
      <v-btn
        text
        color="#3F51B5"
        :disabled="allLoaded"
        @click="getMoreActionItems()"
      >
        Load More
      </v-btn>
    </div>

    <ActionItemTabActionItemDialog
      v-if="showActionItemDialog"
      :showActionItemDialog="showActionItemDialog"
      :selectedActionItem="selectedActionItem"
      :selectedActionItemId="selectedActionItemId"
      :action="getActionItemLabel(selectedActionItem)"
      @action-item-dialog-close="showActionItemDialog = false"
      @action-item-saved="getActionItems"
      @action-item-response-saved="
        showActionItemDialog = false;
        page = 1;
        getActionItems();
      "
      @reject-action-item="
        showActionItemDialog = false;
        page = 1;
        getActionItems();
      "
      @accept-action-item="
        showActionItemDialog = false;
        page = 1;
        getActionItems();
      "
    />

    <EditActionItemDialog
      v-if="showEditActionItemDialog"
      :showEditItemDialog="showEditActionItemDialog"
      :selectedActionItem="selectedActionItemForEdit"
      @action-item-saved="
        showEditActionItemDialog = false;
        page = 1;
        getActionItems();
      "
      @edit-action-item-dialog-close="showEditActionItemDialog = false"
    />

    <template v-if="showExpansionPanelDialog">
      <ExpandInfoPanelDialog
        v-if="['U'].includes(selectedLayerType)"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
        :globalId="selectedFeatureGlobalId"
        :objectId="selectedActionItem.assignerObjectId"
        :selectedGisInfoObj="selectedGisInfoObj"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />
      <ExpandInfoPanelDialog
        v-else-if="['F'].includes(selectedLayerType)"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :selectedMapServiceId="selectedActionItem.assignerMapServiceId"
        :globalId="selectedFeatureGlobalId"
        :objectId="selectedActionItem.assignerObjectId"
        :selectedGisInfoObj="selectedGisInfoObj"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />
    </template>

    <ActionItemsFilterDialog
      v-if="showActionItemsFilterDialog"
      :showActionItemsFilterDialog="showActionItemsFilterDialog"
      :users="users"
      :siteInfos="siteInfos"
      :mapServices="mapServices"
      :savedFilterChoices="filterChoices"
      @action-item-filter-dialog-close="showActionItemsFilterDialog = false"
      @update-filter="
        showActionItemsFilterDialog = false;
        filterChoices = $event;
        page = 1;
        getActionItems();
      "
    />

    <ActionItemsPageConfirmActionItemCancelDialog
      :showConfirmActionItemCancelDialog="showConfirmActionItemCancelDialog"
      @cancel-confirm-cancel="
        showConfirmActionItemCancelDialog = false;
        selectedActionItem = {};
        selectedActionItemId = undefined;
      "
      @confirm-cancel="
        showConfirmActionItemCancelDialog = false;
        cancelActionItem();
      "
    />

    <v-snackbar v-model="showFormSubmittedSnackbar">
      <v-icon dark>
        {{ mdiInformation }}
      </v-icon>
      Success! The form was submitted.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="viewForm"> View Form </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="showViewFormEditFormDialog"
      max-width="600px"
      persistent
      :fullscreen="isFullScreen"
    >
      <v-card>
        <DynamicForm
          :formDefinition="formResultBeingViewed.selectedFormResult"
          :existingFormResultIdMap="
            formResultBeingViewed.existingFormResultIdMap
          "
          :selectedPdfFileUrl="formResultBeingViewed.selectedPdfFileUrl"
          :canEdit="false"
          alreadySubmittedFinalOnline
          :globalId="formResultBeingViewed.globalId"
          :objectId="formResultBeingViewed.objectId"
          :selectedMapServiceId="formResultBeingViewed.selectedMapServiceId"
          @ticket-edit-form-close-button-click="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
          "
          @ticket-edit-form-close="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
          "
          @ticket-edit-form-submitted="
            showViewFormEditFormDialog = false;
            resetFormResultBeingViewed();
          "
          v-if="showViewFormEditFormDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import {
  mdiDotsVertical,
  mdiNotePlusOutline,
  mdiDelete,
  mdiBookOpenOutline,
  mdiPencil,
  mdiFilter,
  mdiPlusCircle,
  mdiClose,
  mdiInformation,
} from "@mdi/js";
import ActionItemTabActionItemDialog from "@/components/mapView/action-items-tab/ActionItemTabActionItemDialog";
import GisInfoTopCard from "@/components/mapView/GisInfoTopCard";
import GisInfoButtonBar from "@/components/mapView/GisInfoButtonBar.vue";
import FeatureItemGisInfoTopCard from "@/components/list/FeatureItemGisInfoTopCard";
import FeatureItemButtonBar from "@/components/list/FeatureItemButtonBar.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import signOutMixin from "@/mixins/signOutMixin";
import Log from "@/components/tickets/Log.vue";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import ExpandInfoPanelDialog from "@/components/mapView/ExpandInfoPanelDialog.vue";
import TABS from "@/constants/tabs";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import ActionItemsFilterDialog from "@/components/action-items/ActionItemsFilterDialog";
import ActionItemsFilterChoicesDisplay from "@/components/action-items/ActionItemsFilterChoicesDisplay";
import EditActionItemDialog from "@/components/mapView/action-items-tab/action-item-dialog/EditActionItemDialog";
import ActionItemsPageConfirmActionItemCancelDialog from "@/components/action-items/ActionItemsPageConfirmActionItemCancelDialog";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import getUtiliSyncLayerFeatureSiteName from "@/mixins/getUtiliSyncLayerFeatureSiteName";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import Dexie from "dexie";
import SharePublicSite from "@/components/app/SharePublicSite";
import { mapGetters, mapMutations } from "vuex";
import TableViewDropdown from "@/components/mapView/TableViewDropdown";
import sharedSiteMixin from "@/mixins/sharedSiteMixin";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import moment from "moment";
import actionItemMixin from "@/mixins/actionItemMixin";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getActiveTaskCountMixin from "@/mixins/getActiveTaskCountMixin";
import SortedGisInfo from "@/components/mapView/SortedGisInfo";

const APIURL = process.env.VUE_APP_API_URL;
const ITEMS_PER_PAGE = 50;
const headers = [
  {
    text: "Due",
    align: "start",
    value: "dueBy",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Created On",
    align: "start",
    value: "createdOn",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Assigned To",
    align: "start",
    value: "assignedTo",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Status",
    align: "start",
    value: "status",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Site",
    align: "start",
    value: "assignerObjectId",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "Description",
    align: "start",
    value: "actionItemDescription",
    sortable: true,
    minWidth: "120px",
    width: "120px",
  },
  {
    text: "",
    align: "start",
    value: "menu",
    sortable: false,
    minWidth: "120px",
    width: "120px",
  },
];

export default {
  name: "MapViewActionItemsView",
  mixins: [
    downloadDataMixin,
    bulkDownloadDataMixin,
    signOutMixin,
    networkStatusMixin,
    sharedSiteMixin,
    actionItemMixin,
    fullScreenCheckMixin,
    getActiveTaskCountMixin,
  ],
  components: {
    NotificationsPane,
    ActionItemTabActionItemDialog,
    GisInfoTopCard,
    GisInfoButtonBar,
    FeatureItemGisInfoTopCard,
    FeatureItemButtonBar,
    TicketFormDefDropdown,
    Log,
    DocsTab,
    TasksTab,
    ExpandInfoPanelDialog,
    ActionItemsFilterDialog,
    ActionItemsFilterChoicesDisplay,
    EditActionItemDialog,
    ActionItemsPageConfirmActionItemCancelDialog,
    TableViewDropdown,
    SharePublicSite,
    DynamicForm,
    SortedGisInfo,
  },
  props: {
    newSelectedTable: String,
    onMapPage: Boolean,
    bottomPaneHeight: Number,
    progressBarHeight: Number,
    inMapView: Boolean,
  },
  computed: {
    ...mapGetters(["formSubmitted", "showingNotificationPane"]),
    selectedLayerFields() {
      return this.selectedLayer?.fields;
    },
    selectedLayer() {
      const selectedLayer = this.mapServices.find(
        (m) =>
          m.map_service_id === this.selectedActionItem?.assignerMapServiceId
      );
      return { ...selectedLayer };
    },
    selectedLayerType() {
      return this.mapServices.find(
        (m) =>
          m.map_service_id === this.selectedActionItem?.assignerMapServiceId
      )?.service_type;
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    rawGisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];
          return [key, value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];

          return [this.findAliasByName(this.featureItemFields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    notUtiliSyncLayer() {
      const mapService = this.mapServices.find(
        (m) =>
          m.map_service_id === this.selectedActionItem?.assignerMapServiceId
      );
      return mapService.service_type !== "U";
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
    mappedActionItems() {
      return this.actionItems.map((a) => {
        const {
          due_by: dueBy,
          created_on: createdOn,
          action_item_description: actionItemDescription,
          assigner_object_id: assignerObjectId,
          assigner_map_service_id: assignerMapServiceId,
          action_item_id: actionItemId,
          status,
          assigned_on: assignedOn,
          assigner_user_id: assignerUserId,
          assignee_user_id: assigneeUserId,
          assignee_email: assigneeEmail,
        } = a;

        const sharedSiteContact = this.sharedSiteContacts?.find(
          (c) => c.user_id === assigneeUserId
        );

        const orgUser = this.users
          .filter((u) => !u.is_contact)
          .find((u) => u.user_id === assigneeUserId);
        const assigneeIsOrgUser = Boolean(orgUser);
        const assigneeIsSiteContact = Boolean(sharedSiteContact);

        let assignedTo;
        if (assigneeIsOrgUser) {
          const { f_name: fName, l_name: lName } = orgUser;
          assignedTo = `${fName} ${lName}`;
        } else if (assigneeIsSiteContact) {
          assignedTo = sharedSiteContact?.email;
        } else {
          assignedTo = assigneeEmail;
        }

        return {
          dueBy,
          createdOn,
          assignedTo,
          assignerObjectId,
          actionItemDescription,
          assignerMapServiceId,
          actionItemId,
          status,
          assignedOn,
          assignerUserId,
          assigneeUserId,
        };
      });
    },
  },
  data() {
    return {
      showNotificationPane: false,
      actionItems: [],
      users: [],
      headers,
      headersEnabled: {
        dueBy: true,
        createdOn: true,
        assignedTo: true,
        formDefinitionId: true,
        actionItemDescription: true,
        assignerObjectId: true,
        status: true,
        actionItemId: false,
        menu: true,
      },
      mdiDotsVertical,
      mdiNotePlusOutline,
      mdiDelete,
      mdiBookOpenOutline,
      mdiPencil,
      mdiFilter,
      mdiPlusCircle,
      mdiClose,
      mdiInformation,
      mapServices: [],
      showActionItemsFilterDialog: false,
      siteInfos: [],
      ITEMS_PER_PAGE,
      selectedActionItem: {},
      showActionItemDialog: false,
      showGisInfoPanel: false,
      showFeatureLayerGisInfoPanel: false,
      featureItemAttributes: {},
      featureItemFields: [],
      selectedGisInfoIndex: 0,
      selectedFeatureGlobalId: undefined,
      showExpansionPanelDialog: false,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      gisInfoTab: undefined,
      filterChoices: [
        {
          selectedField: "status",
          selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
          selectedMatchValue: ACTION_ITEM_STATUSES.OPEN,
        },
      ],
      showEditActionItemDialog: false,
      selectedActionItemForEdit: {},
      showConfirmActionItemCancelDialog: false,
      allLoaded: false,
      page: 1,
      selectedActionItemId: undefined,
      tableHeight: 0,
      taskCount: undefined,
      showFormSubmittedSnackbar: false,
      showViewFormEditFormDialog: false,
      formResultBeingViewed: {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      },
      objectId: undefined,
    };
  },
  methods: {
    ...mapMutations([
      "setIsRedrawLayers",
      "setFormSubmitted",
      "setShowingNotificationPane",
    ]),
    resetFormResultBeingViewed() {
      this.formResultBeingViewed = {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      };
    },
    async viewForm() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { feature_id: objectId, map_service_id: mapServiceId } = formResult;
      const { gisDataPointId } = formResult.feature_attributes;
      this.formResultBeingViewed = {
        selectedFormResult: formResult,
        existingFormResultIdMap: {
          formResultId,
        },
        selectedPdfFileUrl: formResult.pdfFileUrl,
        globalId: gisDataPointId,
        objectId,
        selectedMapServiceId: mapServiceId,
      };
      this.showViewFormEditFormDialog = true;
      this.setFormSubmitted({
        isFormSubmitted: false,
        formResultId: undefined,
      });
    },
    onResize() {
      if (this.inMapView) {
        const height =
          this.bottomPaneHeight -
          this.progressBarHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          20;
        this.tableHeight = Math.max(height, 135);
      } else {
        const height =
          window.innerHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          (this.topBarHeight ?? 56) -
          30;
        this.tableHeight = Math.max(height, 200);
      }
    },
    openConfirmActionItemCancelDialog(item) {
      this.showConfirmActionItemCancelDialog = true;
      this.selectedActionItem = this.actionItems.find(
        (a) => a.action_item_id === item.actionItemId
      );
      this.selectedActionItemId = this.selectedActionItem.action_item_id;
    },
    async toggleOpenStatus() {
      const index = this.filterChoices.findIndex(
        (f) => f.selectedField === "status"
      );
      const { selectedMatchValue } = this.filterChoices[index];
      this.$set(this.filterChoices, index, {
        ...this.filterChoices[index],
        selectedMatchValue:
          selectedMatchValue !== ACTION_ITEM_STATUSES.OPEN
            ? ACTION_ITEM_STATUSES.OPEN
            : ACTION_ITEM_STATUSES.CLOSED,
      });
      await this.getActionItems();
    },
    async cancelActionItem() {
      const selectedActionItem = this.actionItems.find(
        (a) => a.action_item_id === this.selectedActionItemId
      );
      const payload = { ...selectedActionItem, status: "canceled" };
      await axiosWithRegularAuth.put(
        `${APIURL}/action_item/details/${this.selectedActionItemId}`,
        payload
      );
      await this.getActionItems();
    },
    async removeFilter(selectedField) {
      const index = this.filterChoices.findIndex(
        (f) => f.selectedField === selectedField
      );
      this.filterChoices.splice(index, 1);
      await this.getActionItems();
    },
    canEditOrCancel(actionItem) {
      if (
        actionItem?.status === ACTION_ITEM_STATUSES.CLOSED ||
        actionItem?.status === ACTION_ITEM_STATUSES.CANCELED
      ) {
        return false;
      }

      const {
        user_id: userId,
        user_group_id: userGroupId,
        is_gis_admin: isGisAdmin,
        is_sys_admin: isSysAdmin,
      } = JSON.parse(localStorage.getItem("auth"));
      if (isGisAdmin || isSysAdmin) {
        const { assignerUserId, assigneeUserId } = actionItem;
        const assignerOrAssigneeUserGroupIds = this.users
          .filter((u) => {
            return [assignerUserId, assigneeUserId].includes(u.user_id);
          })
          .map((u) => u.user_group_id);
        return assignerOrAssigneeUserGroupIds.includes(userGroupId);
      }
      return actionItem?.assigner_user_id === userId;
    },
    editActionItem(actionItem) {
      const selectedActionItem = this.actionItems.find(
        (a) => a.action_item_id === actionItem.actionItemId
      );
      this.selectedActionItemForEdit = selectedActionItem;
      this.selectedActionItemId = this.selectedActionItemForEdit.action_item_id;
      this.showEditActionItemDialog = true;
    },
    onGisInfoPanelClose() {
      this.showGisInfoPanel = false;
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async openInfoPanel(actionItem) {
      this.gisInfos = [];
      this.selectedActionItem = { ...actionItem };
      this.objectId = this.selectedActionItem.assignerObjectId;
      this.selectedActionItemId = this.selectedActionItem.actionItemId;
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      const selectedLayer = this.mapServices.find(
        (m) => m.map_service_id === actionItem?.assignerMapServiceId
      );
      if (selectedLayer?.service_type === "U") {
        this.showGisInfoPanel = true;
        this.selectedMapServiceId =
          this.selectedActionItem.assignerMapServiceId;
        this.objectId = this.selectedActionItem.assignerObjectId;
        const [gisDataPoint] = await db.gisDataPoints
          .filter(
            (p) =>
              p.map_service_id === actionItem?.assignerMapServiceId &&
              p.object_id === actionItem?.assignerObjectId
          )
          .toArray();
        this.selectedFeatureGlobalId = gisDataPoint?.gis_data_point_id;
        await this.createSharedSiteIfNotExist();
      } else if (selectedLayer?.service_type === "F") {
        let queryResult = {};
        const [results] = await db.mapServices
          .filter((m) => m.map_service_id === actionItem?.assignerMapServiceId)
          .toArray();
        try {
          const mapServiceUrl = results?.service_url;
          const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
            params: {
              objectids: actionItem?.assignerObjectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          });
          queryResult = data;
          const [feature] = queryResult?.features;
          const { globalIdFieldName } = queryResult;
          this.selectedFeatureGlobalId =
            feature?.attributes?.[globalIdFieldName];
        } catch (error) {
          console.log(error);
        } finally {
          const { features, fields } = queryResult;
          const [{ attributes }] = features ?? [{}];
          this.featureItemAttributes = attributes ?? {};
          this.featureItemFields = fields;
          this.showFeatureLayerGisInfoPanel = true;
          this.selectedMapServiceId =
            this.selectedActionItem.assignerMapServiceId;
          this.objectId = this.selectedActionItem.assignerObjectId;
          await this.createSharedSiteIfNotExist();
        }
      }
    },
    getActionName(actionItem) {
      if (actionItem.status === ACTION_ITEM_STATUSES.READY_FOR_REVIEW) {
        return "Review";
      }
      return "Complete";
    },
    onRowClick(actionItem) {
      if (this.canEditOrCancel(actionItem)) {
        this.selectedActionItem = this.actionItems.find(
          (a) => a.action_item_id === actionItem.actionItemId
        );
        this.showActionItemDialog = true;
        this.selectedActionItemId = this.selectedActionItem.action_item_id;
      }
    },
    async getGisInfo() {
      if (!navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/action_item/by_user_group/summary`
      );
      const mapServiceIds = [
        ...new Set(results.map((r) => r.assigner_map_service_id)),
      ];
      const queryPromises = mapServiceIds.map(async (mapServiceId) => {
        const resultsWithMapServiceId = results.filter(
          (r) => r.assigner_map_service_id === mapServiceId
        );
        const objectIds = resultsWithMapServiceId.map(
          (r) => r.assigner_object_id
        );
        const [mapService] = await db.mapServices
          .filter((m) => m.map_service_id === mapServiceId)
          .toArray();
        const mapServiceUrl = mapService?.service_url;
        if (!mapServiceUrl) {
          return;
        }
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              objectids: [...new Set(objectIds)].join(","),
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        const { objectIdFieldName, features } = queryResult;
        return resultsWithMapServiceId.map((r) => {
          const feature = features?.find(
            (f) => f?.attributes?.[objectIdFieldName] === r.assigner_object_id
          );
          return {
            ...r,
            siteName: feature?.attributes?.[mapService?.ref_field],
          };
        });
      });
      const featureLayerSiteNames = (await Promise.all(queryPromises)).flat();

      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const mapServices = await db.mapServices
        .filter((m) => m.service_type === "U")
        .toArray();
      const utiliSyncLayerSiteNamePromises = mapServices.map(async (m) => {
        const { map_service_id: mapServiceId } = m;
        const gisDataPoints = await db.gisDataPoints
          .filter((p) => p.map_service_id === mapServiceId)
          .toArray();
        const promises = gisDataPoints.map(async (p) => {
          const siteName = await getUtiliSyncLayerFeatureSiteName({
            gisDataFields,
            gisDataValues,
            mapServiceId,
            objectId: p.object_id,
          });
          return {
            assigner_object_id: p.object_id,
            assigner_map_service_id: mapServiceId,
            siteName,
          };
        });
        return Promise.all(promises);
      });
      const utiliSyncLayerSiteNames = (
        await Promise.all(utiliSyncLayerSiteNamePromises)
      ).flat();

      this.siteInfos = [...featureLayerSiteNames, ...utiliSyncLayerSiteNames];
    },
    getSiteName(actionItem) {
      const site = this.siteInfos.find((s) => {
        return (
          s?.assigner_object_id === actionItem?.assignerObjectId &&
          s?.assigner_map_service_id === actionItem?.assignerMapServiceId
        );
      });
      return site?.siteName;
    },

    async getMapServices() {
      this.mapServices = await db.mapServices.toCollection().toArray();
    },
    async getActionItems(page = 1) {
      if (!navigator.onLine) {
        return;
      }
      const { filterChoices, siteInfos } = this;
      const newFilterChoices = [
        ...filterChoices.filter((f) => f.selectedField !== "status"),
      ];
      const index = newFilterChoices.findIndex(
        (f) => f.selectedField === "site"
      );
      if (index >= 0) {
        const matchingSiteInfos = siteInfos.filter((s) => {
          return s?.siteName === newFilterChoices?.[index]?.selectedMatchValue;
        });
        newFilterChoices[index] = {
          ...newFilterChoices[index],
          selectedMatchValue: matchingSiteInfos.filter(Boolean),
        };
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.post(
        `${APIURL}/action_item/by_user_group/filter`,
        {
          skip: (page - 1) * ITEMS_PER_PAGE,
          take: ITEMS_PER_PAGE,
          filters: newFilterChoices,
          open_status:
            filterChoices.find((f) => f.selectedField === "status")
              ?.selectedMatchValue === ACTION_ITEM_STATUSES.OPEN,
        }
      );
      this.allLoaded = (results?.length ?? 0) < ITEMS_PER_PAGE;
      if (page === 1) {
        this.actionItems = [...results];
      } else {
        this.actionItems = [...this.actionItems, ...results];
        const actionItemIds = this.actionItems.map((a) => a.action_item_id);
        this.actionItems = this.actionItems.filter((actionItem, index) => {
          return actionItemIds.indexOf(actionItem.action_item_id) === index;
        });
      }
    },
    async getMoreActionItems() {
      this.page++;
      await this.getActionItems(this.page);
    },
    async getUsers() {
      this.users = await db.users.toCollection().toArray();
    },
    getUserName(actionItem) {
      const user = this.users?.find(
        (u) => u.user_id === actionItem?.assignee_user_id
      );
      if (user) {
        const { f_name: fName, l_name: lName } = user;
        return `${fName} ${lName}`;
      } else if (actionItem?.assignedToUserName) {
        return actionItem?.assignedToUserName;
      } else if (actionItem?.assigneeEmail) {
        return actionItem?.assigneeEmail;
      }
    },
    async openActionItemDialogOnPageLoad() {
      const { actionItemId } = this.$route.query;
      if (!actionItemId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/action_item/${actionItemId}`
      );
      this.selectedActionItem = results;
      this.selectedActionItemId = this.selectedActionItem.action_item_id;
      this.showActionItemDialog = true;
    },
    async getData() {
      try {
        if (localStorage.getItem("bulk-download-complete") !== "true") {
          await this.bulkDownloadData();
        }
        localStorage.setItem("bulk-download-complete", true);
        const lastFullDownloadCompleted = localStorage.getItem(
          "last-full-download-completed"
        );
        const fullDownload =
          !lastFullDownloadCompleted ||
          (lastFullDownloadCompleted &&
            moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
        await this.downloadData(fullDownload);
        await this.openActionItemDialogOnPageLoad();
      } catch (error) {
        if (Object.values(Dexie.errnames).includes(error?.name)) {
          this.signOut();
        }
      } finally {
        await this.bulkDownloadUsers();
        this.getActionItems();
        this.getUsers();
        this.getGisInfo();
        this.getMapServices();
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    showingNotificationPane: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$nextTick();
          this.showTicketLogTab = false;
          this.showTasksTab = false;
          this.showDocsTab = false;
          this.showGisInfoPanel = false;
          this.showFeatureLayerGisInfoPanel = false;
          this.setShowingNotificationPane(false);
        }
      },
    },
    formSubmitted: {
      deep: true,
      handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.showFormSubmittedSnackbar = true;
        }
      },
    },
    filterChoices: {
      deep: true,
      handler() {
        this.onResize();
      },
    },
    bottomPaneHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    progressBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    topBarHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    isOnline(val) {
      this.actionItems = [];
      if (val) {
        this.getData();
      }
    },
  },
};
</script>
