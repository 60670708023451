var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          style: {
            "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
            "background-color": "#fafafa"
          },
          attrs: {
            app: !_vm.$vuetify.breakpoint.xsOnly,
            right: "",
            permanent: _vm.showNotificationPane,
            "hide-overlay": "",
            width: "375px",
            stateless: "",
            absolute: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.showNotificationPane,
            callback: function($$v) {
              _vm.showNotificationPane = $$v
            },
            expression: "showNotificationPane"
          }
        },
        [
          _vm.showNotificationPane
            ? _c("NotificationsPane", {
                on: {
                  "close-notification-pane": function($event) {
                    _vm.showNotificationPane = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "elevation-3",
          staticStyle: { "z-index": "100" },
          attrs: {
            app: "",
            "hide-overlay": "",
            width: "375px",
            permanent: _vm.showGisInfoPanel && _vm.$vuetify.breakpoint.smAndUp,
            right: _vm.$vuetify.breakpoint.smAndUp,
            bottom: "",
            stateless: _vm.$vuetify.breakpoint.smAndUp
          },
          model: {
            value: _vm.showGisInfoPanel,
            callback: function($$v) {
              _vm.showGisInfoPanel = $$v
            },
            expression: "showGisInfoPanel"
          }
        },
        [
          _vm.showGisInfoPanel
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column background",
                  staticStyle: { height: "100%" },
                  attrs: { id: "infoPanel" }
                },
                [
                  _c("GisInfoTopCard", {
                    staticClass: "background",
                    attrs: {
                      selectedMapServiceId:
                        _vm.selectedActionItem.assignerMapServiceId,
                      globalId: _vm.selectedFeatureGlobalId,
                      objectId: _vm.selectedActionItem.assignerObjectId,
                      sharedSite: _vm.sharedSite
                    },
                    on: {
                      "gis-info-panel-close": _vm.onGisInfoPanelClose,
                      "open-expand-panel-dialog": function($event) {
                        _vm.showExpansionPanelDialog = true
                      },
                      "edited-site": _vm.createSharedSiteIfNotExist
                    }
                  }),
                  !["S"].includes(_vm.selectedLayerType)
                    ? _c("GisInfoButtonBar", {
                        staticClass: "background",
                        attrs: {
                          selectedMapServiceId:
                            _vm.selectedActionItem.assignerMapServiceId,
                          globalId: _vm.selectedFeatureGlobalId,
                          objectId: _vm.selectedActionItem.assignerObjectId,
                          selectedGisInfoIndex: _vm.selectedGisInfoIndex,
                          taskCount: _vm.taskCount
                        },
                        on: {
                          "gis-info-button-clicked": function($event) {
                            _vm.gisInfoTab = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "background" },
                    [
                      _vm.showGisInfoPanel
                        ? _c("TicketFormDefDropdown", {
                            attrs: {
                              inRightPane: "",
                              selectedMapServiceId:
                                _vm.selectedActionItem.assignerMapServiceId,
                              globalId: _vm.selectedFeatureGlobalId,
                              objectId: _vm.selectedActionItem.assignerObjectId,
                              selectedGisInfoObj: _vm.selectedGisInfoObj
                            },
                            on: {
                              "show-edit-dialog": function($event) {
                                _vm.openGisInfoPanels = [1]
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.showGisInfoPanel
                    ? _c("SharePublicSite", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectedLayer &&
                              _vm.selectedLayer.site_enabled,
                            expression:
                              "selectedLayer && selectedLayer.site_enabled"
                          }
                        ],
                        attrs: {
                          selectedMapServiceId:
                            _vm.selectedActionItem.assignerMapServiceId,
                          objectId: _vm.selectedActionItem.assignerObjectId,
                          attributes: _vm.rawGisInfoAttributes,
                          sharedSite: _vm.sharedSite,
                          globalId: _vm.selectedFeatureGlobalId
                        },
                        on: {
                          "public-link-created": _vm.createSharedSiteIfNotExist,
                          "removed-sharing": _vm.createSharedSiteIfNotExist,
                          "site-name-saved": _vm.createSharedSiteIfNotExist
                        }
                      })
                    : _vm._e(),
                  _vm.selectedLayer && _vm.selectedLayer.site_enabled
                    ? _c("v-divider")
                    : _vm._e(),
                  ["F", "U"].includes(_vm.selectedLayerType)
                    ? _c(
                        "div",
                        {
                          staticClass: "flex-grow-1 overflow-y-auto background"
                        },
                        [
                          _vm.showGisInfoPanel
                            ? _c("SortedGisInfo", {
                                attrs: {
                                  inRightPane: "",
                                  selectedMapServiceId:
                                    _vm.selectedActionItem.assignerMapServiceId,
                                  objectId:
                                    _vm.selectedActionItem.assignerObjectId,
                                  globalId: _vm.selectedFeatureGlobalId
                                },
                                on: {
                                  "esri-field-saved": _vm.getGisInfo,
                                  "utilisync-field-saved": _vm.getGisInfo
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "100" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showFeatureLayerGisInfoPanel,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showFeatureLayerGisInfoPanel,
            callback: function($$v) {
              _vm.showFeatureLayerGisInfoPanel = $$v
            },
            expression: "showFeatureLayerGisInfoPanel"
          }
        },
        [
          _vm.showFeatureLayerGisInfoPanel
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column background",
                  staticStyle: { height: "100%" },
                  attrs: { id: "infoPanel" }
                },
                [
                  _c("FeatureItemGisInfoTopCard", {
                    attrs: {
                      selectedMapServiceId:
                        _vm.selectedActionItem.assignerMapServiceId,
                      objectId: _vm.selectedActionItem.assignerObjectId,
                      globalId: _vm.selectedFeatureGlobalId,
                      attributes: _vm.featureItemAttributes,
                      sharedSite: _vm.sharedSite
                    },
                    on: {
                      "gis-info-panel-close": function($event) {
                        _vm.showFeatureLayerGisInfoPanel = false
                      },
                      "open-expand-panel-dialog": function($event) {
                        _vm.showExpansionPanelDialog = true
                      },
                      "edited-site": _vm.createSharedSiteIfNotExist
                    }
                  }),
                  _c("FeatureItemButtonBar", {
                    attrs: {
                      selectedMapServiceId:
                        _vm.selectedActionItem.assignerMapServiceId,
                      objectId: _vm.selectedActionItem.assignerObjectId,
                      globalId: _vm.selectedFeatureGlobalId,
                      taskCount: _vm.taskCount
                    },
                    on: {
                      "gis-info-button-clicked": function($event) {
                        _vm.gisInfoTab = $event
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "background" },
                    [
                      _vm.showFeatureLayerGisInfoPanel
                        ? _c("TicketFormDefDropdown", {
                            attrs: {
                              inRightPane: "",
                              selectedMapServiceId:
                                _vm.selectedActionItem.assignerMapServiceId,
                              globalId: _vm.selectedFeatureGlobalId,
                              objectId: _vm.selectedActionItem.assignerObjectId,
                              selectedGisInfoObj: _vm.selectedGisInfoObj
                            },
                            on: {
                              "show-edit-dialog": function($event) {
                                _vm.openGisInfoPanels = [1]
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm.showFeatureLayerGisInfoPanel
                    ? _c("SharePublicSite", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectedLayer &&
                              _vm.selectedLayer.site_enabled,
                            expression:
                              "selectedLayer && selectedLayer.site_enabled"
                          }
                        ],
                        attrs: {
                          selectedMapServiceId:
                            _vm.selectedActionItem.assignerMapServiceId,
                          objectId: _vm.selectedActionItem.assignerObjectId,
                          attributes: _vm.rawGisInfoAttributes,
                          sharedSite: _vm.sharedSite,
                          globalId: _vm.selectedFeatureGlobalId
                        },
                        on: {
                          "public-link-created": _vm.createSharedSiteIfNotExist,
                          "removed-sharing": _vm.createSharedSiteIfNotExist,
                          "site-name-saved": _vm.createSharedSiteIfNotExist
                        }
                      })
                    : _vm._e(),
                  _vm.selectedLayer && _vm.selectedLayer.site_enabled
                    ? _c("v-divider")
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 overflow-y-auto background" },
                    [
                      _vm.showFeatureLayerGisInfoPanel
                        ? _c("SortedGisInfo", {
                            attrs: {
                              selectedMapServiceId:
                                _vm.selectedActionItem.assignerMapServiceId,
                              globalId: _vm.selectedFeatureGlobalId,
                              objectId: _vm.selectedActionItem.assignerObjectId
                            },
                            on: {
                              "esri-field-saved": _vm.getGisInfo,
                              "utilisync-field-saved": _vm.getGisInfo
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTasksTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTasksTab,
            callback: function($$v) {
              _vm.showTasksTab = $$v
            },
            expression: "showTasksTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Tasks")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTasksTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTasksTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("TasksTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          },
                          on: {
                            "update-task-count": function($event) {
                              _vm.taskCount = $event
                            }
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("TasksTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          },
                          on: {
                            "update-task-count": function($event) {
                              _vm.taskCount = $event
                            }
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTicketLogTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTicketLogTab,
            callback: function($$v) {
              _vm.showTicketLogTab = $$v
            },
            expression: "showTicketLogTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Log")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTicketLogTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTicketLogTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("Log", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("Log", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showDocsTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showDocsTab,
            callback: function($$v) {
              _vm.showDocsTab = $$v
            },
            expression: "showDocsTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Documents")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showDocsTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showDocsTab
                ? [
                    ["U"].includes(_vm.selectedLayerType)
                      ? _c("DocsTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          }
                        })
                      : ["F"].includes(_vm.selectedLayerType)
                      ? _c("DocsTab", {
                          staticClass: "overflow-y-auto",
                          attrs: {
                            globalId: _vm.selectedFeatureGlobalId,
                            objectId: _vm.selectedActionItem.assignerObjectId,
                            selectedMapServiceId:
                              _vm.selectedActionItem.assignerMapServiceId
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          ref: "filterDisplay",
          staticClass: "d-flex flex-wrap justify-space-between align-center"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex px-5 align-center" },
            [
              _vm.onMapPage
                ? _c("TableViewDropdown", {
                    staticClass: "pt-4",
                    attrs: { currentSelectedTable: this.newSelectedTable },
                    on: {
                      "selected-table": function($event) {
                        return _vm.$emit("selected-table", $event)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "px-1",
                  attrs: { text: "", color: "#3F51B5", id: "actionItemFilter" },
                  on: {
                    click: function($event) {
                      _vm.showActionItemsFilterDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                  _vm._v(" Filter ")
                ],
                1
              ),
              _c("ActionItemsFilterChoicesDisplay", {
                staticClass: "px-2",
                attrs: {
                  filterChoices: _vm.filterChoices,
                  users: _vm.users,
                  siteInfos: _vm.siteInfos,
                  mapServices: _vm.mapServices
                },
                on: {
                  "remove-filter": _vm.removeFilter,
                  "toggle-open-status": _vm.toggleOpenStatus
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-5" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "px-1",
                                  attrs: {
                                    text: "",
                                    color: "#3F51B5",
                                    id: "addFieldActionItems"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { dark: "" } },
                                [_vm._v(" " + _vm._s(_vm.mdiPlusCircle) + " ")]
                              ),
                              _vm._v(" Field ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        _vm._l(_vm.nonEmptyHeaders, function(h) {
                          return _c("v-checkbox", {
                            key: h.value,
                            staticClass: "py-0 my-0",
                            attrs: { label: h.text },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.headersEnabled[h.value],
                              callback: function($$v) {
                                _vm.$set(_vm.headersEnabled, h.value, $$v)
                              },
                              expression: "headersEnabled[h.value]"
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("v-data-table", {
        staticClass: "pa-0 ma-0 cursor-pointer",
        attrs: {
          headers: _vm.filteredHeaders,
          items: _vm.mappedActionItems,
          "item-key": "actionItemId",
          "hide-default-footer": "",
          "disable-pagination": "",
          "fixed-header": "",
          height: _vm.tableHeight
        },
        on: { "click:row": _vm.onRowClick },
        scopedSlots: _vm._u(
          [
            {
              key: "no-data",
              fn: function() {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.isOnline
                          ? "No data available"
                          : "This device is offline. <u>Reload</u> the page when you are back online to load the Action Items"
                      )
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.menu",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          text: "",
                                          id: "actionItemOverflow"
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.mdiDotsVertical) + " "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "px-0 mx-0" },
                        [
                          _vm.canEditOrCancel(item)
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "mx-0 gap",
                                  on: {
                                    click: function($event) {
                                      _vm.selectedActionItem = _vm.actionItems.find(
                                        function(a) {
                                          return (
                                            a.action_item_id ===
                                            item.actionItemId
                                          )
                                        }
                                      )
                                      _vm.selectedActionItemId =
                                        _vm.selectedActionItem.action_item_id
                                      _vm.showActionItemDialog = true
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_vm.mdiNotePlusOutline))
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getActionName(item)) +
                                      " Action Item "
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  return _vm.openInfoPanel(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiBookOpenOutline))
                              ]),
                              _vm._v(" Open Info Panel ")
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              staticClass: "mx-0 gap",
                              on: {
                                click: function($event) {
                                  return _vm.editActionItem(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v(_vm._s(_vm.mdiPencil))]),
                              _vm._v(" Edit Action Item ")
                            ],
                            1
                          ),
                          _vm.canEditOrCancel(item)
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "mx-0 gap",
                                  on: {
                                    click: function($event) {
                                      return _vm.openConfirmActionItemCancelDialog(
                                        item
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v(_vm._s(_vm.mdiDelete))]),
                                  _vm._v(" Cancel Action Item ")
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.status",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.status === "rejected"
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("actionItemStatus")(item.status)) +
                            " "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("actionItemStatus")(item.status)) +
                            " "
                        )
                      ])
                ]
              }
            },
            {
              key: "item.dueBy",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm._f("formatDayDate")(item.dueBy)) + " "
                  )
                ]
              }
            },
            {
              key: "item.createdOn",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " + _vm._s(_vm._f("formatDayDate")(item.createdOn)) + " "
                  )
                ]
              }
            },
            {
              key: "item.assignedToUserName",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getUserName(item)) + " ")]
              }
            },
            {
              key: "item.assignerObjectId",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getSiteName(item)) + " ")]
              }
            },
            {
              key: "item.assignedOn",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatFullDate")(item.assignedOn)) +
                      " "
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { ref: "bottomBar", staticClass: "d-flex justify-center py-2" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "#3F51B5", disabled: _vm.allLoaded },
              on: {
                click: function($event) {
                  return _vm.getMoreActionItems()
                }
              }
            },
            [_vm._v(" Load More ")]
          )
        ],
        1
      ),
      _vm.showActionItemDialog
        ? _c("ActionItemTabActionItemDialog", {
            attrs: {
              showActionItemDialog: _vm.showActionItemDialog,
              selectedActionItem: _vm.selectedActionItem,
              selectedActionItemId: _vm.selectedActionItemId,
              action: _vm.getActionItemLabel(_vm.selectedActionItem)
            },
            on: {
              "action-item-dialog-close": function($event) {
                _vm.showActionItemDialog = false
              },
              "action-item-saved": _vm.getActionItems,
              "action-item-response-saved": function($event) {
                _vm.showActionItemDialog = false
                _vm.page = 1
                _vm.getActionItems()
              },
              "reject-action-item": function($event) {
                _vm.showActionItemDialog = false
                _vm.page = 1
                _vm.getActionItems()
              },
              "accept-action-item": function($event) {
                _vm.showActionItemDialog = false
                _vm.page = 1
                _vm.getActionItems()
              }
            }
          })
        : _vm._e(),
      _vm.showEditActionItemDialog
        ? _c("EditActionItemDialog", {
            attrs: {
              showEditItemDialog: _vm.showEditActionItemDialog,
              selectedActionItem: _vm.selectedActionItemForEdit
            },
            on: {
              "action-item-saved": function($event) {
                _vm.showEditActionItemDialog = false
                _vm.page = 1
                _vm.getActionItems()
              },
              "edit-action-item-dialog-close": function($event) {
                _vm.showEditActionItemDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showExpansionPanelDialog
        ? [
            ["U"].includes(_vm.selectedLayerType)
              ? _c("ExpandInfoPanelDialog", {
                  attrs: {
                    showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                    selectedMapServiceId:
                      _vm.selectedActionItem.assignerMapServiceId,
                    globalId: _vm.selectedFeatureGlobalId,
                    objectId: _vm.selectedActionItem.assignerObjectId,
                    selectedGisInfoObj: _vm.selectedGisInfoObj
                  },
                  on: {
                    "expand-info-panel-close": function($event) {
                      _vm.showExpansionPanelDialog = false
                    }
                  }
                })
              : ["F"].includes(_vm.selectedLayerType)
              ? _c("ExpandInfoPanelDialog", {
                  attrs: {
                    showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                    selectedMapServiceId:
                      _vm.selectedActionItem.assignerMapServiceId,
                    globalId: _vm.selectedFeatureGlobalId,
                    objectId: _vm.selectedActionItem.assignerObjectId,
                    selectedGisInfoObj: _vm.selectedGisInfoObj
                  },
                  on: {
                    "expand-info-panel-close": function($event) {
                      _vm.showExpansionPanelDialog = false
                    }
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm.showActionItemsFilterDialog
        ? _c("ActionItemsFilterDialog", {
            attrs: {
              showActionItemsFilterDialog: _vm.showActionItemsFilterDialog,
              users: _vm.users,
              siteInfos: _vm.siteInfos,
              mapServices: _vm.mapServices,
              savedFilterChoices: _vm.filterChoices
            },
            on: {
              "action-item-filter-dialog-close": function($event) {
                _vm.showActionItemsFilterDialog = false
              },
              "update-filter": function($event) {
                _vm.showActionItemsFilterDialog = false
                _vm.filterChoices = $event
                _vm.page = 1
                _vm.getActionItems()
              }
            }
          })
        : _vm._e(),
      _c("ActionItemsPageConfirmActionItemCancelDialog", {
        attrs: {
          showConfirmActionItemCancelDialog:
            _vm.showConfirmActionItemCancelDialog
        },
        on: {
          "cancel-confirm-cancel": function($event) {
            _vm.showConfirmActionItemCancelDialog = false
            _vm.selectedActionItem = {}
            _vm.selectedActionItemId = undefined
          },
          "confirm-cancel": function($event) {
            _vm.showConfirmActionItemCancelDialog = false
            _vm.cancelActionItem()
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      { attrs: { text: "" }, on: { click: _vm.viewForm } },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" View Form ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showFormSubmittedSnackbar,
            callback: function($$v) {
              _vm.showFormSubmittedSnackbar = $$v
            },
            expression: "showFormSubmittedSnackbar"
          }
        },
        [
          _c("v-icon", { attrs: { dark: "" } }, [
            _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
          ]),
          _vm._v(" Success! The form was submitted. ")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            persistent: "",
            fullscreen: _vm.isFullScreen
          },
          model: {
            value: _vm.showViewFormEditFormDialog,
            callback: function($$v) {
              _vm.showViewFormEditFormDialog = $$v
            },
            expression: "showViewFormEditFormDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.showViewFormEditFormDialog
                ? _c("DynamicForm", {
                    attrs: {
                      formDefinition:
                        _vm.formResultBeingViewed.selectedFormResult,
                      existingFormResultIdMap:
                        _vm.formResultBeingViewed.existingFormResultIdMap,
                      selectedPdfFileUrl:
                        _vm.formResultBeingViewed.selectedPdfFileUrl,
                      canEdit: false,
                      alreadySubmittedFinalOnline: "",
                      globalId: _vm.formResultBeingViewed.globalId,
                      objectId: _vm.formResultBeingViewed.objectId,
                      selectedMapServiceId:
                        _vm.formResultBeingViewed.selectedMapServiceId
                    },
                    on: {
                      "ticket-edit-form-close-button-click": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                      },
                      "ticket-edit-form-close": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                      },
                      "ticket-edit-form-submitted": function($event) {
                        _vm.showViewFormEditFormDialog = false
                        _vm.resetFormResultBeingViewed()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }