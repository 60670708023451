var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap gap" },
    [
      _c("v-chip", { attrs: { id: "actionItemStatusChip" } }, [
        _c(
          "div",
          {
            staticClass:
              "chip overflow-hidden text-truncate my-0 py-0 cursor-pointer",
            on: {
              click: function($event) {
                return _vm.$emit("toggle-open-status")
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm._f("actionItemFields")("status")) + " "),
            _c("MatchIcon", {
              staticClass: "mx-2",
              attrs: { value: _vm.selectedStatus.selectedMatchChoice }
            }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.getDisplayMatchValue(
                    "status",
                    _vm.selectedStatus.selectedMatchValue
                  )
                ) +
                " "
            ),
            _c("v-icon", { staticClass: "ml-1" }, [
              _vm._v(" " + _vm._s(_vm.mdiSyncCircle) + " ")
            ])
          ],
          1
        )
      ]),
      _vm._l(_vm.filterChoicesExceptStatus, function(f) {
        return _c(
          "v-chip",
          {
            key: f.selectedField,
            attrs: { close: "" },
            on: {
              "click:close": function($event) {
                return _vm.$emit("remove-filter", f.selectedField)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "chip overflow-hidden text-truncate my-0 py-0" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("actionItemFields")(f.selectedField)) +
                    " "
                ),
                _c("MatchIcon", {
                  staticClass: "mx-2",
                  attrs: { value: f.selectedMatchChoice }
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDisplayMatchValue(
                        f.selectedField,
                        f.selectedMatchValue
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }