<template>
  <div class="d-flex flex-wrap gap">
    <v-chip id="actionItemStatusChip">
      <div
        class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
        @click="$emit('toggle-open-status')"
      >
        {{ "status" | actionItemFields }}
        <MatchIcon :value="selectedStatus.selectedMatchChoice" class="mx-2" />
        {{ getDisplayMatchValue("status", selectedStatus.selectedMatchValue) }}
        <v-icon class="ml-1">
          {{ mdiSyncCircle }}
        </v-icon>
      </div>
    </v-chip>
    <v-chip
      v-for="f of filterChoicesExceptStatus"
      :key="f.selectedField"
      close
      @click:close="$emit('remove-filter', f.selectedField)"
    >
      <div class="chip overflow-hidden text-truncate my-0 py-0">
        {{ f.selectedField | actionItemFields }}
        <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
        {{ getDisplayMatchValue(f.selectedField, f.selectedMatchValue) }}
      </div>
    </v-chip>
  </div>
</template>

<script>
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import { mdiSyncCircle } from "@mdi/js";
import ACTION_ITEM_STATUSES_MAP from "@/constants/actionItemStatusesMap";

export default {
  name: "ActionItemsFilterChoicesDisplay",
  props: {
    filterChoices: Array,
    users: Array,
    siteInfos: Array,
    mapServices: Array,
  },
  data() {
    return {
      mdiSyncCircle,
    };
  },
  components: {
    MatchIcon,
  },
  computed: {
    filterChoicesExceptStatus() {
      return this.filterChoices.filter((f) => f.selectedField !== "status");
    },
    selectedStatus() {
      return this.filterChoices.find((f) => f.selectedField === "status");
    },
  },
  methods: {
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "assignedTo") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        const { f_name: fName, l_name: lName } = user ?? {};
        return fName && lName ? `${fName} ${lName}` : selectedMatchValue;
      } else if (selectedField === "status") {
        return ACTION_ITEM_STATUSES_MAP[selectedMatchValue];
      }
      return selectedMatchValue;
    },
  },
};
</script>
