var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showActionItemsFilterDialog,
        "max-width": "600px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "actionItemFilterDialog" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v("Filter")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("action-item-filter-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c("div", [_vm._v("Filter")]),
              _c(
                "v-card",
                { staticClass: "mb-5 px-0", attrs: { elevation: "0" } },
                [
                  _c("v-card-text", { staticClass: "px-0 mx-0" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap gap" },
                      [
                        _c("v-chip", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "chip overflow-hidden text-truncate my-0 py-0"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("actionItemFields")("status")) +
                                  " "
                              ),
                              _c("MatchIcon", {
                                staticClass: "mx-2",
                                attrs: {
                                  value: _vm.selectedStatus.selectedMatchChoice
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDisplayMatchValue(
                                      "status",
                                      _vm.selectedStatus.selectedMatchValue
                                    )
                                  ) +
                                  " "
                              ),
                              _c("v-icon", { staticClass: "ml-1" }, [
                                _vm._v(" " + _vm._s(_vm.mdiSyncCircle) + " ")
                              ])
                            ],
                            1
                          )
                        ]),
                        _vm._l(_vm.filterChoicesExceptStatus, function(f) {
                          return _c(
                            "v-chip",
                            {
                              key: f.selectedField,
                              attrs: { close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFilter(f.selectedField)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "chip overflow-hidden text-truncate my-0 py-0"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("actionItemFields")(
                                          f.selectedField
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("MatchIcon", {
                                    staticClass: "mx-2",
                                    attrs: { value: f.selectedMatchChoice }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDisplayMatchValue(
                                          f.selectedField,
                                          f.selectedMatchValue
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ],
                1
              ),
              _c("div", [_vm._v("Add to Filter")]),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "align-center justify-space-between gap",
                      class: { "d-flex": _vm.$vuetify.breakpoint.smAndUp }
                    },
                    [
                      _c("v-select", {
                        style: {
                          width: _vm.$vuetify.breakpoint.smAndUp
                            ? "100%"
                            : undefined
                        },
                        attrs: {
                          items: _vm.fieldChoices,
                          "item-text": "label",
                          "item-value": "value",
                          label: "Field",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selectedField,
                          callback: function($$v) {
                            _vm.selectedField = $$v
                          },
                          expression: "selectedField"
                        }
                      }),
                      _c("v-select", {
                        class: {
                          "field-select": _vm.$vuetify.breakpoint.smAndUp
                        },
                        staticStyle: { width: "100px" },
                        attrs: {
                          items: _vm.filteredMatchChoices,
                          "item-text": "label",
                          "item-value": "value",
                          flat: "",
                          "hide-details": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var ref_item = ref.item
                              var label = ref_item.label
                              var value = ref_item.value
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("MatchIcon", {
                                      attrs: { value: value }
                                    }),
                                    _vm._v(" " + _vm._s(label) + " ")
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var value = ref.item.value
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("MatchIcon", { attrs: { value: value } })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedMatchChoice,
                          callback: function($$v) {
                            _vm.selectedMatchChoice = $$v
                          },
                          expression: "selectedMatchChoice"
                        }
                      }),
                      _c(
                        "div",
                        {
                          style: {
                            width: _vm.$vuetify.breakpoint.smAndUp
                              ? "100%"
                              : undefined
                          }
                        },
                        [
                          _vm.fieldType === _vm.FIELD_TYPES.DATE
                            ? _c(
                                "v-menu",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: { "hide-details": "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "append",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "#3F51B5"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.mdiCalendar
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.selectedMatchValue,
                                                    callback: function($$v) {
                                                      _vm.selectedMatchValue = $$v
                                                    },
                                                    expression:
                                                      "selectedMatchValue"
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3640546164
                                  )
                                },
                                [
                                  _c("v-date-picker", {
                                    model: {
                                      value: _vm.selectedMatchValue,
                                      callback: function($$v) {
                                        _vm.selectedMatchValue = $$v
                                      },
                                      expression: "selectedMatchValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : [
                                _vm.selectedField === "assignedTo"
                                  ? _c("v-combobox", {
                                      ref: "combobox",
                                      attrs: {
                                        items: _vm.valueChoices,
                                        "item-text": "label",
                                        "item-value": "value",
                                        "return-object": false,
                                        "hide-details": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getDisplayMatchValue(
                                                        _vm.selectedField,
                                                        item
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1447989256
                                      ),
                                      model: {
                                        value: _vm.selectedMatchValue,
                                        callback: function($$v) {
                                          _vm.selectedMatchValue =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "selectedMatchValue"
                                      }
                                    })
                                  : [
                                      _vm.MATCH_CHOICE_VALUES.EQUALS,
                                      _vm.MATCH_CHOICE_VALUES.DOES_NOT_EQUAL
                                    ].includes(_vm.selectedMatchChoice)
                                  ? _c("v-autocomplete", {
                                      staticClass: "autocomplete",
                                      attrs: {
                                        items: _vm.valueChoices,
                                        "item-text": "label",
                                        "item-value": "value",
                                        "hide-details": ""
                                      },
                                      model: {
                                        value: _vm.selectedMatchValue,
                                        callback: function($$v) {
                                          _vm.selectedMatchValue = $$v
                                        },
                                        expression: "selectedMatchValue"
                                      }
                                    })
                                  : _c("v-text-field", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.selectedMatchValue,
                                        callback: function($$v) {
                                          _vm.selectedMatchValue = $$v
                                        },
                                        expression: "selectedMatchValue"
                                      }
                                    })
                              ]
                        ],
                        2
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "#3F51B5" },
                          on: { click: _vm.addToFilter }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.mdiFilterPlus) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    elevation: "2",
                    color: "#3F51B5",
                    disabled: !_vm.filterChanged,
                    dark: _vm.filterChanged
                  },
                  on: { click: _vm.updateFilter }
                },
                [
                  _c("span", { attrs: { color: "white" } }, [
                    _vm._v("Update Filter")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }