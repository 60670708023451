<template>
  <v-dialog
    v-model="showConfirmActionItemCancelDialog"
    max-width="600"
    close
    persistent
  >
    <v-card>
      <v-card-text class="py-5">
        <h2 class="font-weight-regular">Cancel Action Item</h2>
        <p class="pt-5">
          Are you sure you want to cancel this action item? This action can’t be
          undone.
        </p>
      </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end gap">
        <v-btn text @click="$emit('cancel-confirm-cancel')"> Cancel </v-btn>
        <v-btn color="#3F51B5" dark @click="$emit('confirm-cancel')">
          Cancel Action Item
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionItemsPageConfirmActionItemCancelDialog",
  props: {
    showConfirmActionItemCancelDialog: Boolean,
  },
};
</script>
