<template>
  <v-dialog :value="showActionItemsFilterDialog" max-width="600px" persistent>
    <v-card id="actionItemFilterDialog">
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Filter</div>
        <v-spacer />
        <v-btn icon @click="$emit('action-item-filter-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <div>Filter</div>
        <v-card class="mb-5 px-0" elevation="0">
          <v-card-text class="px-0 mx-0">
            <div class="d-flex flex-wrap gap">
              <v-chip>
                <div class="chip overflow-hidden text-truncate my-0 py-0">
                  {{ "status" | actionItemFields }}
                  <MatchIcon
                    :value="selectedStatus.selectedMatchChoice"
                    class="mx-2"
                  />
                  {{
                    getDisplayMatchValue(
                      "status",
                      selectedStatus.selectedMatchValue
                    )
                  }}
                  <v-icon class="ml-1"> {{ mdiSyncCircle }} </v-icon>
                </div>
              </v-chip>
              <v-chip
                v-for="f of filterChoicesExceptStatus"
                :key="f.selectedField"
                close
                @click:close="removeFilter(f.selectedField)"
              >
                <div class="chip overflow-hidden text-truncate my-0 py-0">
                  {{ f.selectedField | actionItemFields }}
                  <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
                  {{
                    getDisplayMatchValue(f.selectedField, f.selectedMatchValue)
                  }}
                </div>
              </v-chip>
            </div>
          </v-card-text>
        </v-card>

        <div>Add to Filter</div>
        <v-card>
          <v-card-text
            class="align-center justify-space-between gap"
            :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
          >
            <v-select
              :items="fieldChoices"
              v-model="selectedField"
              item-text="label"
              item-value="value"
              label="Field"
              :style="{
                width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
              }"
              hide-details
            >
            </v-select>

            <v-select
              :items="filteredMatchChoices"
              v-model="selectedMatchChoice"
              item-text="label"
              item-value="value"
              flat
              hide-details
              :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
              style="width: 100px"
            >
              <template #item="{ item: { label, value } }">
                <div>
                  <MatchIcon :value="value" />
                  {{ label }}
                </div>
              </template>

              <template #selection="{ item: { value } }">
                <div>
                  <MatchIcon :value="value" />
                </div>
              </template>
            </v-select>

            <div
              :style="{
                width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
              }"
            >
              <v-menu v-if="fieldType === FIELD_TYPES.DATE">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="selectedMatchValue"
                    hide-details
                  >
                    <template #append>
                      <v-icon color="#3F51B5">
                        {{ mdiCalendar }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="selectedMatchValue"> </v-date-picker>
              </v-menu>
              <template v-else>
                <v-combobox
                  v-if="selectedField === 'assignedTo'"
                  v-model.trim="selectedMatchValue"
                  :items="valueChoices"
                  item-text="label"
                  item-value="value"
                  :return-object="false"
                  ref="combobox"
                  hide-details
                >
                  <template #selection="{ item }">
                    {{ getDisplayMatchValue(selectedField, item) }}
                  </template>
                </v-combobox>
                <v-autocomplete
                  v-else-if="
                    [
                      MATCH_CHOICE_VALUES.EQUALS,
                      MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
                    ].includes(selectedMatchChoice)
                  "
                  v-model="selectedMatchValue"
                  :items="valueChoices"
                  item-text="label"
                  item-value="value"
                  hide-details
                  class="autocomplete"
                >
                </v-autocomplete>
                <v-text-field v-else v-model="selectedMatchValue" hide-details>
                </v-text-field>
              </template>
            </div>

            <v-btn icon color="#3F51B5" @click="addToFilter">
              <v-icon>
                {{ mdiFilterPlus }}
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn
          elevation="2"
          color="#3F51B5"
          @click="updateFilter"
          :disabled="!filterChanged"
          :dark="filterChanged"
        >
          <span color="white">Update Filter</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiFilterPlus,
  mdiCalendar,
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
  mdiSyncCircle,
} from "@mdi/js";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import FIELD_TYPES from "@/constants/fieldTypes";
import { cloneDeep } from "lodash";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import ACTION_ITEM_STATUSES_MAP from "@/constants/actionItemStatusesMap";

const fieldChoices = [
  { label: "Due", value: "due", fieldType: FIELD_TYPES.DATE },
  { label: "Assigned To", value: "assignedTo", fieldType: FIELD_TYPES.STRING },
  { label: "Site", value: "site", fieldType: FIELD_TYPES.STRING },
  { label: "Description", value: "description", fieldType: FIELD_TYPES.STRING },
  { label: "Status", value: "status", fieldType: FIELD_TYPES.STRING },
];

const statusChoices = [
  { label: "Open", value: ACTION_ITEM_STATUSES.OPEN },
  { label: "Ready for Review", value: ACTION_ITEM_STATUSES.READY_FOR_REVIEW },
  { label: "Rejected", value: ACTION_ITEM_STATUSES.REJECTED },
  { label: "Canceled", value: ACTION_ITEM_STATUSES.CANCELED },
  { label: "Closed", value: ACTION_ITEM_STATUSES.CLOSED },
];

export default {
  name: "ActionItemsFilterDialog",
  data() {
    return {
      mdiClose,
      mdiFilterPlus,
      mdiCalendar,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      mdiSyncCircle,
      FIELD_TYPES,
      MATCH_CHOICE_VALUES,
      fieldChoices,
      statusChoices,
      filterChoices: [],
      selectedMatchValue: undefined,
      selectedMatchChoice: undefined,
      selectedField: undefined,
      filterChanged: false,
    };
  },
  props: {
    savedFilterChoices: Array,
    showActionItemsFilterDialog: Boolean,
    users: Array,
    siteInfos: Array,
    mapServices: Array,
  },
  components: {
    MatchIcon,
  },
  computed: {
    filterChoicesExceptStatus() {
      return this.filterChoices.filter((f) => f.selectedField !== "status");
    },
    selectedStatus() {
      return this.filterChoices.find((f) => f.selectedField === "status");
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }

      const activeUsers = this.users
        .filter((u) => u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Active Users",
          };
        });
      const inactiveUsers = this.users
        .filter((u) => !u.is_active)
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: value } = u;
          return {
            label: `${fName} ${lName}`,
            value,
            group: "Inactive Users",
          };
        });
      return [
        { header: "Active Users" },
        ...activeUsers,
        { header: "Inactive Users" },
        ...inactiveUsers,
      ];
    },
    siteChoices() {
      return (
        this.siteInfos
          ?.map((s) => {
            return {
              label: s?.siteName,
              value: s?.siteName,
            };
          })
          ?.filter((s) => s.label && s.value) ?? []
      );
    },
    valueChoices() {
      if (
        this.selectedField === "assignedTo" ||
        this.selectedField === "assignedBy"
      ) {
        return this.userChoices;
      } else if (this.selectedField === "site") {
        return this.siteChoices;
      } else if (this.selectedField === "status") {
        return statusChoices;
      }
      return [];
    },
    fieldType() {
      return this.fieldChoices.find((f) => f.value === this.selectedField)
        ?.fieldType;
    },
    filteredMatchChoices() {
      const { fieldType, selectedField } = this;
      if (fieldType === FIELD_TYPES.STRING) {
        if (selectedField === "description") {
          return [
            { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
            {
              label: "Does Not Contain",
              value: MATCH_CHOICE_VALUES.DOES_NOT_CONTAIN,
            },
          ];
        } else if (["status", "site", "assignedTo"].includes(selectedField)) {
          return [
            { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
            {
              label: "Does not Equal",
              value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
            },
          ];
        } else {
          return [{ label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS }];
        }
      } else if (fieldType === FIELD_TYPES.NUMBER) {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Greater Than", value: MATCH_CHOICE_VALUES.GREATER_THAN },
          {
            label: "Greater Than or Equal",
            value: MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL,
          },
          { label: "Less Than", value: MATCH_CHOICE_VALUES.LESS_THAN },
          {
            label: "Less Than or Equal",
            value: MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL,
          },
          {
            label: "Does not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      } else if (fieldType === FIELD_TYPES.DATE) {
        return [
          {
            label: "On or After",
            value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
          },
          {
            label: "On or Before",
            value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
          },
          {
            label: "Range",
            value: MATCH_CHOICE_VALUES.RANGE,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    async addToFilter() {
      this.$refs.combobox?.blur();
      await this.$nextTick();
      const { selectedField, selectedMatchChoice, selectedMatchValue } = this;
      const filter = { selectedField, selectedMatchChoice, selectedMatchValue };
      const index = this.filterChoices.findIndex((fc) => {
        return fc.selectedField === selectedField;
      });
      if (index >= 0) {
        this.filterChoices.splice(index, 1);
      }
      this.filterChoices.push(filter);
      this.selectedField = undefined;
      this.selectedMatchChoice = MATCH_CHOICE_VALUES.EQUALS;
      this.selectedMatchValue = undefined;
      this.filterChanged = true;
    },
    removeFilter(selectedField) {
      const index = this.filterChoices.findIndex(
        (f) => f.selectedField === selectedField
      );
      this.filterChoices.splice(index, 1);
      this.filterChanged = true;
    },
    getDisplayMatchValue(selectedField, selectedMatchValue) {
      if (selectedField === "assignedTo") {
        const user = this.users?.find((u) => u.user_id === selectedMatchValue);
        if (user) {
          const { f_name: fName, l_name: lName } = user;
          return `${fName} ${lName}`;
        } else {
          return selectedMatchValue;
        }
      } else if (selectedField === "status") {
        return ACTION_ITEM_STATUSES_MAP[selectedMatchValue];
      }
      return selectedMatchValue;
    },
    updateFilter() {
      this.$emit("update-filter", this.filterChoices);
    },
  },
  watch: {
    filteredMatchChoices: {
      deep: true,
      handler(val) {
        const [firstChoice] = val;
        this.selectedMatchChoice = firstChoice?.value;
      },
    },
  },
  beforeMount() {
    this.filterChoices = cloneDeep(this.savedFilterChoices);
  },
};
</script>

<style scoped>
.field-select >>> .v-input__slot::before {
  border-style: none;
}

.text-style-dropdown >>> .v-input {
  max-width: unset;
  max-width: 60px;
}
</style>
